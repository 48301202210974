<template>
    <div class="d-flex all-after-header-box">
        <!-- Левое меню модуля -->
        <div 
            :class="{'drawer-left-wrap' : true, 'dlw-large' : (!drawerLeftMenuMini), 'drawer-menu-hover': drawerLeftMenuHover }"
            @mouseenter="LeftMenuMouseenter()"
            @mouseleave="LeftMenuMouseleave()"
        >
            <v-navigation-drawer
                absolute
                permanent
                class="drawer-menu"
                v-model="drawerLeftMenu"
            >

                <v-divider class="mr-2 ml-2"></v-divider>

                <v-list
                    nav
                    dense
                    shaped
                >

                    <v-list-group
                        v-model="group1.active" 
                        :accordion="true"
                        @input="closeOtherGroups('group1')"
                    >
                        <template v-slot:prependIcon>
                            <v-icon>far fa-books</v-icon>
                        </template>
                        <template v-slot:activator>
                            <v-list-item-title>{{ $t("Архив_документов") }}</v-list-item-title>
                        </template>
                        <template v-slot:appendIcon>
                            <v-icon>fas fa-chevron-down</v-icon>
                        </template>

                        <template v-for="year in years.Documents">
                            <v-list-item
                                :key="'ArchiveDocuments' + year"
                                link
                                :to="{ name: 'ArchiveDocuments', params: { year: year.toString() } }"
                            >
                                <v-list-item-icon><v-icon>far fa-circle</v-icon></v-list-item-icon>
                                <v-list-item-title>{{ year }}</v-list-item-title>
                            </v-list-item>
                        </template>

                    </v-list-group>

                    <v-list-group
                        v-model="group2.active" 
                        :accordion="true"
                        @input="closeOtherGroups('group2')"
                    >
                        <template v-slot:prependIcon>
                            <v-icon>far fa-mail-bulk</v-icon>
                        </template>
                        <template v-slot:activator>
                            <v-list-item-title>{{ $t("Архив_обращений") }}</v-list-item-title>
                        </template>
                        <template v-slot:appendIcon>
                            <v-icon>fas fa-chevron-down</v-icon>
                        </template>

                        <template v-for="year in years.Euol">
                            <v-list-item
                                :key="'ArchiveEuol' + year"
                                link
                                :to="{ name: 'ArchiveEuol', params: { year: year.toString() } }"
                            >
                                <v-list-item-icon><v-icon>far fa-circle</v-icon></v-list-item-icon>
                                <v-list-item-title>{{ year }}</v-list-item-title>
                            </v-list-item>
                        </template>

                    </v-list-group> 
                                      
                </v-list>

            </v-navigation-drawer>
        </div>

        <div class="content-box">

            <router-view :key="$router.fullPath" />
            
        </div>

    </div>
</template>

<script>
import { mapGetters} from 'vuex';
import { httpAPI/*, errorHandler*/ } from "@/api/httpAPI";

export default {
    name: "ArchiveMain",
    components: {
    },
    asyncComputed: {
        years: {
            async get() {
                var payload = (await httpAPI.get(`api/archive/years`))?.data?.Payload ?? [[], []];

                return { Documents: payload[0], Euol: payload[1] };
            },
            default: { Documents: [], Euol: [] }
        }
    },
    computed: {
        ...mapGetters(
            {
                drawerLeftMenuMini: 'isDrawerLeftMenuMinimized'
            }),
    },
    data: () => ({
        drawerLeftMenu: true,
        drawerLeftMenuHover: false,

        group1: {
            active: false
        },
        group2: {
            active: false
        },

        //urls: []
    }),
    methods: {
        LeftMenuMouseenter() {
            if (this.drawerLeftMenuMini)
                this.drawerLeftMenuHover = true;
        },
        LeftMenuMouseleave() {
            this.drawerLeftMenuHover = false;  
        },
        closeOtherGroups(groupName) {
            if (groupName === 'group1') {
                this.group2.active = false;
            } else if (groupName === 'group2') {
                this.group1.active = false;
            }
        }
    },
    mounted() {
        /*
        this.$eventBus.$on('on-get-report', async repData => {
            let reportRequst = httpAPI({ url: repData.url, method: 'GET', responseType: 'blob', skipErrorHandler: true });

            // при успешном выполнении запроса на формирование отчёта выполнить:
            var onResolve = (response) => {
                this.$notify.success(`${this.$t(repData.text)}`);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                this.urls.push(url);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', repData.type === 'PDF' ? `${this.$t(repData.text)}.pdf` : `${this.$t(repData.text)}.xls` );
                document.body.appendChild(link);
                link.click();
            };
            // при неудачном выполнении запроса на формирование отчёта выполнить:
            var onReject = (error) => {
                errorHandler(error);
            };
            
            let asyncReportToastMessage = `${ this.$t(repData.text) }`;
            this.$notify.async(reportRequst, onResolve, onReject, asyncReportToastMessage, { icons: { enabled: true } });
        });
        */
    },
    beforeDestroy() {
        /*
        this.$eventBus.$off('on-get-report');

        this.urls.forEach(url => URL.revokeObjectURL(url));
        window.removeEventListener('resize', this.slideLeftMenuMethod);
        */
    }
}
</script>